code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-viewer,
.adsk-viewing-viewer {
  background: #9ddeff;
}

.adsk-control, .adsk-toolbar{
  margin-bottom: 40px;
}