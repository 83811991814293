* {
  font-family: 'Open Sans', sans-serif; 
}

html, body { overflow: hidden; height: 100%; }
body { margin: 0 !important; padding: 0 !important;}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-alert {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index:2;
}

.App-alert > * + * {
  margin-top: 1rem;
}

.ng-chat.support-chat-icon {
  position: relative!important;
  right: 0rem!important;
  top: 0rem!important;
  width: 4.87rem!important;
  height: 4.87rem!important;
}
.ng-chat.support-chat-icon,
.spartez-support-chat-container .top-header,
.new-conversation {
  background-color: #4400FF !important;
}

.qr-reader section div {
  border: none!important;
  box-shadow: none!important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.super-app-theme--cell {
  background-color: rgba(224, 183, 60, 0.55);
  color: #1a3e72;
  font-weight: 600;
}
.super-app.negative {
  background-color: rgba(157, 255, 118, 0.49);
  color: #1a3e72;
  font-weight: 600;
}
.super-app.positive {
  background-color: #d47483;
  color: #1a3e72;
  font-weight: 600;
}
